
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexf3ell0234A9ChUZfDLV_450bNGNhKOyvqHHhS92qaxjgEMeta } from "/tmp/build_52b44c15/pages/index.vue?macro=true";
import { default as confirmL8KjUEOTl2dgiu30XaCgX67HzqsCllj0U5atrdcg5SoMeta } from "/tmp/build_52b44c15/pages/confirm.vue?macro=true";
import { default as supportZn3W412OrRTHsjMAhHtGYVmmWnrDdVq5rk8HT2KROmsMeta } from "/tmp/build_52b44c15/pages/support.vue?macro=true";
import { default as indexcJTfG06yoyNDm7uaWHfb95PgWStYtL4UtOltIK5GcgoMeta } from "/tmp/build_52b44c15/pages/map/index.vue?macro=true";
import { default as indexfrOecDNQDB088NNvali5_45JGXF84f2InlsJ4FF2YCGmgMeta } from "/tmp/build_52b44c15/pages/zemma/index.vue?macro=true";
import { default as team63jldEOBToQNO1gwr_MYBoc65aHIL1WHdbBNEOpL2bwMeta } from "/tmp/build_52b44c15/pages/company/team.vue?macro=true";
import { default as imprintLbW_Tii8RoxEBa_45YWmesdflXtdowJIeOE8XZVagu1rwMeta } from "/tmp/build_52b44c15/pages/legal/imprint.vue?macro=true";
import { default as indexvUlbuvJaOSH_45sLajBjxe9PFQzEVsgezEe_v7TmYnfgUMeta } from "/tmp/build_52b44c15/pages/map/feed/index.vue?macro=true";
import { default as indexjo0bxbK58G_45RMOqLYLXUt25pkpjLYekHKRKo2ZE9VdwMeta } from "/tmp/build_52b44c15/pages/dashboard/index.vue?macro=true";
import { default as indexNocAg_45kbosLqgdQrxbKbnHsVwl9RQAHQCHzvsuLgiOgMeta } from "/tmp/build_52b44c15/pages/auth/sign-in/index.vue?macro=true";
import { default as indexETr37BpGwHxTfruAmAGN3AiW86uHG_45DdtqaH1ZfcrdUMeta } from "/tmp/build_52b44c15/pages/auth/register/index.vue?macro=true";
import { default as error_45test_45page_45quoWaCXuU1dwBo_AVNimpl8Z3d_7avVlXVEOFOlYMLqE3MMeta } from "/tmp/build_52b44c15/pages/error-test-page-quo.vue?macro=true";
import { default as cancelkVlrHFdoIs9U3fn_DCv6DUaVj6YFfxKNOeGtfpwZQDkMeta } from "/tmp/build_52b44c15/pages/auth/checkout/cancel.vue?macro=true";
import { default as privacy_45policygNXc8aNpSjlcjawBDON7d89zyXnsM6L7shklD8p3_nkMeta } from "/tmp/build_52b44c15/pages/legal/privacy-policy.vue?macro=true";
import { default as successbMEwCJ0NJq4OS5svY_45hmYebpxfwjvxuVYJdvHqVolfAMeta } from "/tmp/build_52b44c15/pages/auth/checkout/success.vue?macro=true";
import { default as share_45propVG_TL6c1PvCf1wqp13kWI9DGyUMdbDoJhixQ9hTuIAMeta } from "/tmp/build_52b44c15/pages/auth/invite/share-pro.vue?macro=true";
import { default as index1hkJXd2HPh5d1EbugdfkmKJM9EhUyUG6dwLaDqR1L6gMeta } from "/tmp/build_52b44c15/pages/dashboard/users/index.vue?macro=true";
import { default as _91_91id_93_93q5fnt_458C2kzEBgDl6q7KzVGFfzRUk5J6VwyTKtUTuaQMeta } from "/tmp/build_52b44c15/pages/dashboard/lists/[[id]].vue?macro=true";
import { default as _91_91id_93_93gmVyK_45W7G9DVNyElSqnfZXdSDR4CHrGv_Kg_45E2Rl_450oMeta } from "/tmp/build_52b44c15/pages/dashboard/media/[[id]].vue?macro=true";
import { default as terms_45of_45servicelcGwbMTNoDqiC_KtHeKJ9j6UEn__456gLfG3QwqdZOvU0Meta } from "/tmp/build_52b44c15/pages/legal/terms-of-service.vue?macro=true";
import { default as _91_91id_93_93_45VIrqfXJ68vsj0VAr7A_HiJ_YQO1sYHNmwvk6uZ2VdsMeta } from "/tmp/build_52b44c15/pages/dashboard/guides/[[id]].vue?macro=true";
import { default as _91_91id_93_93ecbeDCt_Tgf2YfzEtNtDU5H5wb3muUfOgqLp0QkmelwMeta } from "/tmp/build_52b44c15/pages/dashboard/places/[[id]].vue?macro=true";
import { default as forward_45urlXMktSbqcQEN_45U83pEWsijrWPAuzush5LsmCGC4Ydm90Meta } from "/tmp/build_52b44c15/pages/auth/register/forward-url.vue?macro=true";
import { default as confirm_45emailhTdEmUMKPZnBm7nfSHrFAIkwGZSb1uMe0J_45WnRvwiQwMeta } from "/tmp/build_52b44c15/pages/auth/register/confirm-email.vue?macro=true";
import { default as reset_45passwordgGAHUmh21qxDMyS_45UhPl_454VLX67oOpstVyiMsjhBfuAMeta } from "/tmp/build_52b44c15/pages/auth/sign-in/reset-password.vue?macro=true";
import { default as _91_91slug_93_93Usao5HVnwe3M8vuRPSan9tm529KyJmpzYU5_45HoWJpOkMeta } from "/tmp/build_52b44c15/pages/map/place/[nanoId]/[[slug]].vue?macro=true";
import { default as choose_45productTVqeUIAwnOfl8qBrOW3Ua2mJ_gawQAeX_j9yGuFdib0Meta } from "/tmp/build_52b44c15/pages/auth/checkout/choose-product.vue?macro=true";
import { default as create_45profilemx_60SiOOosz0ff4A2w1_45uXDFFHoq2Y2Y_069IAYfUcMeta } from "/tmp/build_52b44c15/pages/auth/register/create-profile.vue?macro=true";
import { default as _91_91id_93_93nBHUQ_1T_456haaQM4CLGXGJ3AvdLoK5LgEO5L_w4J1W0Meta } from "/tmp/build_52b44c15/pages/dashboard/helper/tags/[[id]].vue?macro=true";
import { default as _91_91slug_93_93lUarNbC4htdW6pbQ0BepUXx_F_45SFTr6aw7TR5pYmOx4Meta } from "/tmp/build_52b44c15/pages/map/list/[[nanoId]]/[[slug]].vue?macro=true";
import { default as success_45quo_45pro6_JWTwnQmFVU6pu_45rCKsgSt1_X9PsZQZo30mfekYbdIMeta } from "/tmp/build_52b44c15/pages/auth/checkout/success-quo-pro.vue?macro=true";
import { default as share_45pro_45successa5kdZPLeQGGEY_tqV3FKnmFl81mZUT_45shAoL2GwG23sMeta } from "/tmp/build_52b44c15/pages/auth/invite/share-pro-success.vue?macro=true";
import { default as send_45password_45linkooi80elL3onH_45eyy63nQmJ8BPH_62F8ILc_aHV7xMNgMeta } from "/tmp/build_52b44c15/pages/auth/sign-in/send-password-link.vue?macro=true";
import { default as indexrvh36iaUAjGxJzjibA3vQtKeNQ5XmrcZFesBeO6rfRgMeta } from "/tmp/build_52b44c15/pages/dashboard/helper/countries/index.vue?macro=true";
import { default as _91highlightUrlName_93G0A6o_45TcY2CL8SFlpQ_45Kl31GVL0X3IY0krUEg3syhzIMeta } from "/tmp/build_52b44c15/pages/map/highlight/[highlightUrlName].vue?macro=true";
import { default as indexjm_acv6Tb_CGQckLkjt7tRC3kE1hnO108lcpd_45UhoSkMeta } from "/tmp/build_52b44c15/pages/map/guide/[nanoId]/[title]/[[key]]/index.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/",
    meta: indexf3ell0234A9ChUZfDLV_450bNGNhKOyvqHHhS92qaxjgEMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/index.vue")
  },
  {
    name: "confirm___en",
    path: "/confirm",
    component: () => import("/tmp/build_52b44c15/pages/confirm.vue")
  },
  {
    name: "support___en",
    path: "/support",
    component: () => import("/tmp/build_52b44c15/pages/support.vue")
  },
  {
    name: "map___en",
    path: "/map",
    meta: indexcJTfG06yoyNDm7uaWHfb95PgWStYtL4UtOltIK5GcgoMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/map/index.vue")
  },
  {
    name: "zemma___en",
    path: "/zemma",
    component: () => import("/tmp/build_52b44c15/pages/zemma/index.vue")
  },
  {
    name: "company-team___en",
    path: "/company/team",
    component: () => import("/tmp/build_52b44c15/pages/company/team.vue")
  },
  {
    name: "legal-imprint___en",
    path: "/legal/imprint",
    component: () => import("/tmp/build_52b44c15/pages/legal/imprint.vue")
  },
  {
    name: "map-feed___en",
    path: "/map/feed",
    meta: indexvUlbuvJaOSH_45sLajBjxe9PFQzEVsgezEe_v7TmYnfgUMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/map/feed/index.vue")
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    meta: indexjo0bxbK58G_45RMOqLYLXUt25pkpjLYekHKRKo2ZE9VdwMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/dashboard/index.vue")
  },
  {
    name: "auth-sign-in___en",
    path: "/auth/sign-in",
    meta: indexNocAg_45kbosLqgdQrxbKbnHsVwl9RQAHQCHzvsuLgiOgMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/sign-in/index.vue")
  },
  {
    name: "auth-register___en",
    path: "/auth/register",
    meta: indexETr37BpGwHxTfruAmAGN3AiW86uHG_45DdtqaH1ZfcrdUMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/register/index.vue")
  },
  {
    name: "error-test-page-quo___en",
    path: "/error-test-page-quo",
    component: () => import("/tmp/build_52b44c15/pages/error-test-page-quo.vue")
  },
  {
    name: "auth-checkout-cancel___en",
    path: "/auth/checkout/cancel",
    meta: cancelkVlrHFdoIs9U3fn_DCv6DUaVj6YFfxKNOeGtfpwZQDkMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/checkout/cancel.vue")
  },
  {
    name: "legal-privacy-policy___en",
    path: "/legal/privacy-policy",
    component: () => import("/tmp/build_52b44c15/pages/legal/privacy-policy.vue")
  },
  {
    name: "auth-checkout-success___en",
    path: "/auth/checkout/success",
    meta: successbMEwCJ0NJq4OS5svY_45hmYebpxfwjvxuVYJdvHqVolfAMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/checkout/success.vue")
  },
  {
    name: "auth-invite-share-pro___en",
    path: "/auth/invite/share-pro",
    meta: share_45propVG_TL6c1PvCf1wqp13kWI9DGyUMdbDoJhixQ9hTuIAMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/invite/share-pro.vue")
  },
  {
    name: "dashboard-users___en",
    path: "/dashboard/users",
    meta: index1hkJXd2HPh5d1EbugdfkmKJM9EhUyUG6dwLaDqR1L6gMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/dashboard/users/index.vue")
  },
  {
    name: "dashboard-lists-id___en",
    path: "/dashboard/lists/:id?",
    meta: _91_91id_93_93q5fnt_458C2kzEBgDl6q7KzVGFfzRUk5J6VwyTKtUTuaQMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/dashboard/lists/[[id]].vue")
  },
  {
    name: "dashboard-media-id___en",
    path: "/dashboard/media/:id?",
    meta: _91_91id_93_93gmVyK_45W7G9DVNyElSqnfZXdSDR4CHrGv_Kg_45E2Rl_450oMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/dashboard/media/[[id]].vue")
  },
  {
    name: "legal-terms-of-service___en",
    path: "/legal/terms-of-service",
    component: () => import("/tmp/build_52b44c15/pages/legal/terms-of-service.vue")
  },
  {
    name: "dashboard-guides-id___en",
    path: "/dashboard/guides/:id?",
    meta: _91_91id_93_93_45VIrqfXJ68vsj0VAr7A_HiJ_YQO1sYHNmwvk6uZ2VdsMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/dashboard/guides/[[id]].vue")
  },
  {
    name: "dashboard-places-id___en",
    path: "/dashboard/places/:id?",
    meta: _91_91id_93_93ecbeDCt_Tgf2YfzEtNtDU5H5wb3muUfOgqLp0QkmelwMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/dashboard/places/[[id]].vue")
  },
  {
    name: "auth-register-forward-url___en",
    path: "/auth/register/forward-url",
    meta: forward_45urlXMktSbqcQEN_45U83pEWsijrWPAuzush5LsmCGC4Ydm90Meta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/register/forward-url.vue")
  },
  {
    name: "auth-register-confirm-email___en",
    path: "/auth/register/confirm-email",
    meta: confirm_45emailhTdEmUMKPZnBm7nfSHrFAIkwGZSb1uMe0J_45WnRvwiQwMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/register/confirm-email.vue")
  },
  {
    name: "auth-sign-in-reset-password___en",
    path: "/auth/sign-in/reset-password",
    meta: reset_45passwordgGAHUmh21qxDMyS_45UhPl_454VLX67oOpstVyiMsjhBfuAMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/sign-in/reset-password.vue")
  },
  {
    name: "map-place-nanoId-slug___en",
    path: "/map/place/:nanoId()/:slug?",
    meta: _91_91slug_93_93Usao5HVnwe3M8vuRPSan9tm529KyJmpzYU5_45HoWJpOkMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/map/place/[nanoId]/[[slug]].vue")
  },
  {
    name: "auth-checkout-choose-product___en",
    path: "/auth/checkout/choose-product",
    meta: choose_45productTVqeUIAwnOfl8qBrOW3Ua2mJ_gawQAeX_j9yGuFdib0Meta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/checkout/choose-product.vue")
  },
  {
    name: "auth-register-create-profile___en",
    path: "/auth/register/create-profile",
    meta: create_45profilemx_60SiOOosz0ff4A2w1_45uXDFFHoq2Y2Y_069IAYfUcMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/register/create-profile.vue")
  },
  {
    name: "dashboard-helper-tags-id___en",
    path: "/dashboard/helper/tags/:id?",
    meta: _91_91id_93_93nBHUQ_1T_456haaQM4CLGXGJ3AvdLoK5LgEO5L_w4J1W0Meta || {},
    component: () => import("/tmp/build_52b44c15/pages/dashboard/helper/tags/[[id]].vue")
  },
  {
    name: "map-list-nanoId-slug___en",
    path: "/map/list/:nanoId?/:slug?",
    meta: _91_91slug_93_93lUarNbC4htdW6pbQ0BepUXx_F_45SFTr6aw7TR5pYmOx4Meta || {},
    component: () => import("/tmp/build_52b44c15/pages/map/list/[[nanoId]]/[[slug]].vue")
  },
  {
    name: "auth-checkout-success-quo-pro___en",
    path: "/auth/checkout/success-quo-pro",
    meta: success_45quo_45pro6_JWTwnQmFVU6pu_45rCKsgSt1_X9PsZQZo30mfekYbdIMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/checkout/success-quo-pro.vue")
  },
  {
    name: "auth-invite-share-pro-success___en",
    path: "/auth/invite/share-pro-success",
    meta: share_45pro_45successa5kdZPLeQGGEY_tqV3FKnmFl81mZUT_45shAoL2GwG23sMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/invite/share-pro-success.vue")
  },
  {
    name: "auth-sign-in-send-password-link___en",
    path: "/auth/sign-in/send-password-link",
    meta: send_45password_45linkooi80elL3onH_45eyy63nQmJ8BPH_62F8ILc_aHV7xMNgMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/auth/sign-in/send-password-link.vue")
  },
  {
    name: "dashboard-helper-countries___en",
    path: "/dashboard/helper/countries",
    meta: indexrvh36iaUAjGxJzjibA3vQtKeNQ5XmrcZFesBeO6rfRgMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/dashboard/helper/countries/index.vue")
  },
  {
    name: "map-highlight-highlightUrlName___en",
    path: "/map/highlight/:highlightUrlName()",
    meta: _91highlightUrlName_93G0A6o_45TcY2CL8SFlpQ_45Kl31GVL0X3IY0krUEg3syhzIMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/map/highlight/[highlightUrlName].vue")
  },
  {
    name: "map-guide-nanoId-title-key___en",
    path: "/map/guide/:nanoId()/:title()/:key?",
    meta: indexjm_acv6Tb_CGQckLkjt7tRC3kE1hnO108lcpd_45UhoSkMeta || {},
    component: () => import("/tmp/build_52b44c15/pages/map/guide/[nanoId]/[title]/[[key]]/index.vue")
  }
]