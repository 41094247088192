
// @ts-nocheck


export const localeCodes =  [
  "en"
]

export const localeLoaders = {
  en: []
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/3153d18b" /* webpackChunkName: "config_i18n_46config_46ts_3153d18b" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      language: "en",
      name: "English",
      shortName: "EN"
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "config",
  pages: {
    "auth/sign-in": {
      en: "/auth/sign-in",
      de: "/auth/sign-in",
      fr: "/auth/sign-in"
    },
    "company/team": {
      en: "/company/team",
      de: "/unternehmen/team",
      fr: "/entreprise/team"
    },
    "legal/privacy-policy": {
      en: "/legal/privacy-policy",
      de: "/rechtliches/datenschutz",
      fr: "/légal/politique-de-confidentialité"
    },
    "legal/terms-of-service": {
      en: "/legal/terms-of-service",
      de: "/rechtliches/nutzungsbedingungen",
      fr: "/légal/conditions-dutilisation"
    },
    "legal/imprint": {
      en: "/legal/imprint",
      de: "/rechtliches/impressum",
      fr: "/légal/mentions-légales"
    },
    "map/feed/index": {
      en: "/map/feed",
      de: "/karte/feed",
      fr: "/carte/feed"
    },
    "map/index": {
      en: "/map",
      de: "/karte",
      fr: "/carte"
    },
    "map/guide/[nanoId]/[title]": {
      de: "/karte/guide/[nanoId]/[title]",
      fr: "/carte/guide/[nanoId]/[title]"
    },
    "map/list/[nanoId]/[[slug]]": {
      de: "/karte/liste/[nanoId]/[[slug]]",
      fr: "/carte/liste/[nanoId]/[[slug]]"
    },
    "map/place/[nanoId]/[[slug]]": {
      de: "/karte/ort/[nanoId]/[[slug]]",
      fr: "/carte/place/[nanoId]/[[slug]]"
    },
    "/support": {
      en: "/support",
      de: "/unterstützung",
      fr: "/soutien"
    }
  },
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    language: "en",
    name: "English",
    shortName: "EN",
    files: []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/