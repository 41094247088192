import { createBrowserClient } from "@supabase/ssr";
import { createClient } from "@supabase/supabase-js";
import { fetchWithRetry } from "../utils/fetch-retry.js";
import { defineNuxtPlugin, useRuntimeConfig, useSupabaseSession, useSupabaseUser } from "#imports";
export default defineNuxtPlugin({
  name: "supabase",
  enforce: "pre",
  async setup({ provide }) {
    const { url, key, cookieOptions, cookiePrefix, useSsrCookies, clientOptions } = useRuntimeConfig().public.supabase;
    let client;
    if (useSsrCookies) {
      client = createBrowserClient(url, key, {
        ...clientOptions,
        cookieOptions: {
          ...cookieOptions,
          name: cookiePrefix
        },
        isSingleton: true,
        global: {
          fetch: fetchWithRetry,
          ...clientOptions.global
        }
      });
    } else {
      client = createClient(url, key, {
        ...clientOptions,
        global: {
          fetch: fetchWithRetry,
          ...clientOptions.global
        }
      });
    }
    provide("supabase", { client });
    const currentSession = useSupabaseSession();
    const currentUser = useSupabaseUser();
    const {
      data: { session }
    } = await client.auth.getSession();
    currentSession.value = session;
    currentUser.value = session?.user ?? null;
    client.auth.onAuthStateChange((_, session2) => {
      if (JSON.stringify(currentSession.value) !== JSON.stringify(session2)) {
        currentSession.value = session2;
        currentUser.value = session2?.user ?? null;
      }
    });
  }
});
