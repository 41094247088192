import { joinURL } from "ufo";
import { isArray, isObject } from "@intlify/shared";
import { unref, useNuxtApp, useRuntimeConfig } from "#imports";
import { getNormalizedLocales } from "./utils.js";
import { getRouteBaseName, localeRoute, switchLocalePath } from "./routing.js";
import { getComposer } from "../compatibility.js";
export function creatHeadContext({ key, seo }) {
  const nuxtApp = useNuxtApp();
  const { defaultDirection } = useRuntimeConfig().public.i18n;
  const locale = unref(nuxtApp.$i18n.locale);
  const locales = getNormalizedLocales(unref(nuxtApp.$i18n.locales));
  const currentLocale = locales.find((l) => l.code === locale) || { code: locale };
  return {
    key,
    seo,
    locale,
    locales,
    currentDir: currentLocale.dir || defaultDirection,
    currentLocale,
    currentLanguage: currentLocale.language,
    baseUrl: getBaseUrl()
  };
}
export function localeHead(common, { dir = true, lang = true, seo = true, key = "hid" }) {
  const metaObject = {
    htmlAttrs: {},
    link: [],
    meta: []
  };
  const ctx = creatHeadContext({ seo, key });
  if (!ctx.baseUrl) {
    console.warn("I18n `baseUrl` is required to generate valid SEO tag links.");
  }
  if (ctx.locales == null || ctx.baseUrl == null) {
    return metaObject;
  }
  if (dir) {
    metaObject.htmlAttrs.dir = ctx.currentDir;
  }
  if (lang && ctx.currentLanguage) {
    metaObject.htmlAttrs.lang = ctx.currentLanguage;
  }
  if (seo && ctx.locale && ctx.locales) {
    metaObject.link.push(
      ...getHreflangLinks(common, ctx),
      ...getCanonicalLink(common, ctx)
    );
    metaObject.meta.push(
      ...getOgUrl(common, ctx),
      ...getCurrentOgLocale(ctx),
      ...getAlternateOgLocales(ctx)
    );
  }
  return metaObject;
}
function getBaseUrl() {
  const nuxtApp = useNuxtApp();
  const i18n = getComposer(nuxtApp.$i18n);
  return joinURL(unref(i18n.baseUrl), nuxtApp.$config.app.baseURL);
}
export function getHreflangLinks(common, ctx) {
  const { defaultLocale, strategy } = useRuntimeConfig().public.i18n;
  const links = [];
  if (strategy === "no_prefix") return links;
  const localeMap = /* @__PURE__ */ new Map();
  for (const locale of ctx.locales) {
    if (!locale.language) {
      console.warn("Locale `language` ISO code is required to generate alternate link");
      continue;
    }
    const [language, region] = locale.language.split("-");
    if (language && region && (locale.isCatchallLocale || !localeMap.has(language))) {
      localeMap.set(language, locale);
    }
    localeMap.set(locale.language, locale);
  }
  const strictCanonicals = common.runtimeConfig.public.i18n.experimental.alternateLinkCanonicalQueries === true;
  const routeWithoutQuery = strictCanonicals ? common.router.resolve({ query: {} }) : void 0;
  if (!common.runtimeConfig.public.i18n.experimental.switchLocalePathLinkSSR && strictCanonicals) {
    routeWithoutQuery.meta = common.router.currentRoute.value.meta;
  }
  for (const [language, mapLocale] of localeMap.entries()) {
    const localePath = switchLocalePath(common, mapLocale.code, routeWithoutQuery);
    const canonicalQueryParams = getCanonicalQueryParams(common, ctx);
    let href = toAbsoluteUrl(localePath, ctx.baseUrl);
    if (canonicalQueryParams && strictCanonicals) {
      href = `${href}?${canonicalQueryParams}`;
    }
    if (localePath) {
      links.push({
        [ctx.key]: `i18n-alt-${language}`,
        rel: "alternate",
        href,
        hreflang: language
      });
    }
  }
  if (defaultLocale) {
    const localePath = switchLocalePath(common, defaultLocale, routeWithoutQuery);
    const canonicalQueryParams = getCanonicalQueryParams(common, ctx);
    let href = toAbsoluteUrl(localePath, ctx.baseUrl);
    if (canonicalQueryParams && strictCanonicals) {
      href = `${href}?${canonicalQueryParams}`;
    }
    if (localePath) {
      links.push({
        [ctx.key]: "i18n-xd",
        rel: "alternate",
        href,
        hreflang: "x-default"
      });
    }
  }
  return links;
}
export function getCanonicalUrl(common, ctx) {
  const route = common.router.currentRoute.value;
  const currentRoute = localeRoute(common, {
    ...route,
    path: void 0,
    name: getRouteBaseName(common, route)
  });
  if (!currentRoute) return "";
  let href = toAbsoluteUrl(currentRoute.path, ctx.baseUrl);
  const canonicalQueryParams = getCanonicalQueryParams(common, ctx);
  if (canonicalQueryParams) {
    href = `${href}?${canonicalQueryParams}`;
  }
  return href;
}
export function getCanonicalLink(common, ctx) {
  const href = getCanonicalUrl(common, ctx);
  if (!href) return [];
  return [{ [ctx.key]: "i18n-can", rel: "canonical", href }];
}
export function getCanonicalQueryParams(common, ctx) {
  const route = common.router.currentRoute.value;
  const currentRoute = localeRoute(common, {
    ...route,
    path: void 0,
    name: getRouteBaseName(common, route)
  });
  const canonicalQueries = isObject(ctx.seo) && ctx.seo.canonicalQueries || [];
  const currentRouteQueryParams = currentRoute?.query || {};
  const params = new URLSearchParams();
  for (const queryParamName of canonicalQueries) {
    if (queryParamName in currentRouteQueryParams) {
      const queryParamValue = currentRouteQueryParams[queryParamName];
      if (isArray(queryParamValue)) {
        queryParamValue.forEach((v) => params.append(queryParamName, v || ""));
      } else {
        params.append(queryParamName, queryParamValue || "");
      }
    }
  }
  return params.toString() || void 0;
}
export function getOgUrl(common, ctx) {
  const href = getCanonicalUrl(common, ctx);
  if (!href) return [];
  return [{ [ctx.key]: "i18n-og-url", property: "og:url", content: href }];
}
export function getCurrentOgLocale(ctx) {
  if (!ctx.currentLocale || !ctx.currentLanguage) return [];
  return [{ [ctx.key]: "i18n-og", property: "og:locale", content: hyphenToUnderscore(ctx.currentLanguage) }];
}
export function getAlternateOgLocales(ctx) {
  const alternateLocales = ctx.locales.filter((locale) => locale.language && locale.language !== ctx.currentLanguage);
  return alternateLocales.map((locale) => ({
    [ctx.key]: `i18n-og-alt-${locale.language}`,
    property: "og:locale:alternate",
    content: hyphenToUnderscore(locale.language)
  }));
}
function hyphenToUnderscore(str) {
  return (str || "").replace(/-/g, "_");
}
function toAbsoluteUrl(urlOrPath, baseUrl) {
  if (urlOrPath.match(/^https?:\/\//)) return urlOrPath;
  return joinURL(baseUrl, urlOrPath);
}
